exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-andor-js": () => import("./../../../src/pages/andor.js" /* webpackChunkName: "component---src-pages-andor-js" */),
  "component---src-pages-dndhuman-js": () => import("./../../../src/pages/dndhuman.js" /* webpackChunkName: "component---src-pages-dndhuman-js" */),
  "component---src-pages-eldenring-js": () => import("./../../../src/pages/eldenring.js" /* webpackChunkName: "component---src-pages-eldenring-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-starwars-js": () => import("./../../../src/pages/starwars.js" /* webpackChunkName: "component---src-pages-starwars-js" */),
  "component---src-pages-terms-service-js": () => import("./../../../src/pages/terms-service.js" /* webpackChunkName: "component---src-pages-terms-service-js" */)
}

